import React, { useState, useEffect, useMemo } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import Contact from "../components/sections/contact"

const imageProps = {
    layout:"fullWidth",
    quality:45,
    formats:["auto", "webp", "avif"],
    alt:"",
    placeholder:"none",
    transformOptions:{ fit: 'contain' },
}

const Promotion = () => {

    const [modal, setModal] = useState(0)
    const [verticalModal, setVerticalModal] = useState(0);
    const [caption, setCaption] = useState("")

    const PromoImages = ({index, item}) => {
        try {
            switch(index){
                case 1:
                    return <StaticImage src={`../../images/promotion/promotion-1-holy.png`} alt={item} {...imageProps}/> // Updated image
                case 2:
                    return <StaticImage src={`../../images/promotion/promotion-2-theloft.png`} alt={item} {...imageProps}/> // Updated image
                case 3:
                    return <StaticImage src={`../../images/promotion/promotion-3-halzan.jpg`} alt={item} {...imageProps}/>
                case 4:
                    return <StaticImage src={`../../images/promotion/promotion-4-richiamo.jpeg`} alt={item} {...imageProps}/>
                case 5:
                    return <StaticImage src={`../../images/promotion/promotion-5-kanada.jpg`} alt={item} {...imageProps}/>
                case 6:
                    return <StaticImage src={`../../images/promotion/promotion-6-coffebean.jpg`} alt={item} {...imageProps}/>
                case 7:
                    return <StaticImage src={`../../images/promotion/promotion-7-winter.jpg`} alt={item} {...imageProps}/> // Updated image
                case 8:
                    return <StaticImage src={`../../images/promotion/promotion-8-miraku.jpeg`} alt={item} {...imageProps}/> // Updated image
                case 9:
                    return <StaticImage src={`../../images/promotion/promotion-9-treebar.jpg`} alt={item} {...imageProps}/> // Updated image
                case 10:
                    return <StaticImage src={`../../images/promotion/promotion-10-antipodean.jpg`} alt={item} {...imageProps}/>
                case 11:
                    return <StaticImage src={`../../images/promotion/promotion-12-chilis.jpeg`} alt={item} {...imageProps}/>
                case 12:
                    return <img src="/promotion/promotion-13-minimelts.jpg" alt={item} className="w-full h-full object-cover"/>
                case 13:
                    return <img src="/promotion/promotion-14-dipndip.jpg" alt={item} className="w-full h-full object-cover"/>
                default:
                    console.warn(`No image found for index ${index}`);
                    return null;
            }
        } catch (error) {
            console.error(`Error loading image for index ${index}:`, error);
            return <div>Error loading image</div>;
        }
    }

    const VerticalImages = ({index, item}) => {
        switch(index){
            case 1:
                return <img src="/promotion/car.jpg" alt="car"></img>
            case 2:
                return <img src="/promotion/dis.jpg" alt="dis"></img>
            default:
                return null;
        }
    }

    const sliderArray = useMemo(()=> [
        "The Holy Guacamole",
        "The Loft",
        "Halzan by Wheeler's",
        "Richiamo Coffee",
        "Kanada-Ya",
        "The Coffee Bean & Tea Leaf",
        "Winter Warmers Coffee & Tea House",
        "Miraku",
        "Tree Bar",
        "Antipodean",
        "Chilis",
        "Minimelts",
        "Dip n Dip",
    ], [])

    useEffect(()=>{
        setCaption(sliderArray[modal-1])
    }, [modal, sliderArray])

    const CloseButton = ({ handleClose }) => {
        return(
            <button 
                onClick={handleClose}
                className="absolute top-0 right-0 flex flex-col justify-center items-center w-16 h-16 ml-auto mr-6 transition-basic z-50" >
                    <div className="transition-basic transform rotate-45 translate-y-[22px] bg-white w-2/4 h-[2px] mb-5"></div>
                    <div className="transition-basic transform -rotate-45 bg-white w-2/4 h-[2px]"></div>
            </button>
        )
    }

    return(
        <Layout>
        <Seo title="Central@Gurney" />

        {
            typeof window !== 'undefined' && 
            <>
                <div className="container mx-auto">
                    <div className=" border rounded-lg mx-8 mt-24 lg:mt-16 mb-16 py-16">
                        <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-5">
                            Promotions
                        </h3>
                        
                        <div className="flex flex-row flex-wrap gap-8 items-center justify-center mx-auto md:w-1/2 mb-8">
                            <div className="relative md:flex-1 md:hover:scale-[1.075] transition-basic">
                                <button className="absolute z-50 w-full h-full" onClick={()=>setVerticalModal(1)}>{' '}</button>
                                <img src="/promotion/car.jpg" alt="car"></img>
                            </div>
                            
                            <div className="relative md:flex-1 md:hover:scale-[1.075] transition-basic">
                                <button className="absolute z-50 w-full h-full" onClick={()=>setVerticalModal(2)}>{' '}</button>
                                <img src="/promotion/dis.jpg" alt="car"></img>
                            </div>

                            <div className={`modal ${verticalModal !== 0 ? 'visible opacity-100':'invisible opacity-0'} transition-basic`}>
                                <CloseButton handleClose={() => setVerticalModal(0)} />
                                <div className="bg-black bg-opacity-75 p-3 w-screen h-screen flex items-center justify-center" >
                                    <button onClick={()=>setVerticalModal(0)} className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default"> </button>
                                    <div className="w-11/12 lg:w-[39vw] mx-auto relative z-50 ">
                                        <div className="max-h-full max-w-full">
                                            <VerticalImages index={verticalModal}/>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-center items-center mx-12">
                            
                            {sliderArray.map((item, i) => (
                                <div className="w-full relative" key={"promotion-"+i}>
                                    <div className="relative md:hover:scale-[1.075] transition-basic md:my-5">
                                        <button className="absolute z-50 w-full h-full" onClick={()=>setModal(i+1)}>{' '}</button>
                                        <PromoImages index={i+1} item={item}/>
                                    </div>
                                    <div className="px-3 pt-3 md:pt-0 mb-2 text-dark text-center">
                                        <span className="font-normal">{item}</span>
                                    </div>
                                </div>
                            ))}
                            <div className={`modal ${modal !== 0 ? 'visible opacity-100':'invisible opacity-0'} transition-basic`}>
                                <CloseButton handleClose={() => setModal(0)} />
                                <div className="bg-black bg-opacity-75 p-3 w-full h-full flex items-center justify-center" >
                                    <button onClick={()=>setModal(0)} className="absolute top-0 right-0 bottom-0 left-0 -z-1 cursor-default"> </button>
                                    <div className="w-11/12 lg:w-[100vh] mx-auto relative z-50">
                                        <div className="">
                                            <div className="w-full aspect-video">
                                                <PromoImages index={modal} item={caption}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            <Contact/>
            </>
        }
        </Layout>
    )
}

export default Promotion